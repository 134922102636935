<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header" v-if="isShownByVariableCode">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              參數設定 
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ codeName }}設定 
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="page-header" v-else>
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: isByMerchant
                  ? 'OrganizationVariableListByMerchant'
                  : 'OrganizationVariableList',
              }"
            >
              組織參數管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active> 組織參數設定 </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold" v-if="isShownByVariableCode">{{ codeName }} 設定</h4>
          <h4 class="font-weight-bold" v-else >{{ organization.name }} 組織設定</h4>
          <b-button class="ml-4" variant="inverse-warning" v-if="isShownByVariableCode && !editable" @click="canEdit = true">
            <span class="mdi mdi-lead-pencil"></span>
            編輯
          </b-button>
          <b-button class="ml-4" variant="inverse-info" v-else-if="isShownByVariableCode && editable" @click="canEdit = false">
            <span class="mdi mdi-eye"></span>
            查看
          </b-button>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="組織參數名稱"
        >
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="name"
            readonly
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="組織參數代號"
        >
          <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="code"
            readonly
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="forms.length === 1"
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="forms[0] && forms[0].name"
          class="group pt-4"
        >
          <b-form-checkbox
            v-if="forms[0].type == 'boolean'"
            :id="forms[0].key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            :name="forms[0].key"
            :value="true"
            :unchecked-value="false"
            :disabled="!editable"
          ></b-form-checkbox>
          <color-picker
            :id="forms[0].key"
            v-model="form[forms[0].key]"
            v-else-if="forms[0].type == 'color'"
            :disabled="!editable"
          ></color-picker>
          <b-form-checkbox-group
            v-else-if="
              forms[0].type == 'array' &&
              forms[0].config &&
              forms[0].config['ui-data'] &&
              forms[0].config['ui-data']['type'] == 'array-checkbox'
            "
            :id="forms[0].key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            :options="forms[0].config['ui-data']['options']"
            :name="forms[0].key"
            :disabled="!editable"
          ></b-form-checkbox-group>
          <editor
            v-else-if="forms[0].type == 'json' || forms[0].type == 'array'"
            :id="forms[0].key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            width="100%"
            height="200px"
            @init="editorInit"
            lang="json"
            theme="chrome"
            :disabled="!editable"
          >
          </editor>
          <editor
            v-else-if="forms[0].type == 'html'"
            :id="forms[0].key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            width="100%"
            height="200px"
            @init="editorInit"
            lang="html"
            theme="chrome"
            :disabled="!editable"
          >
          </editor>
          <b-form-input
            v-else-if="forms[0].type == 'number'"
            :id="forms[0].key"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            v-model="form[forms[0].key]"
            :disabled="!editable"
          ></b-form-input>
          <button
            v-else-if="forms[0].type == 'file'"
            class="btn btn-link px-2 text-dark align-self-start upload-button"
            :disabled="!editable"
          >
            <b-form-file @change="upload($event, forms[0].key)" :disable="!editable" />
            <a
              v-if="!!form[forms[0].key]"
              @click="form[forms[0].key] = null"
              class="btn-sm btn-outline-danger"
              ><i class="fa fa-trash-o"></i
            ></a>
            <a :href="form[forms[0].key]" target="_blank"
              ><small>
                目前路徑：{{ form[forms[0].key] || "未上傳" }}
              </small></a
            >
          </button>
          <b-form-select
            :disabled="!editable"
            v-else-if="
              forms[0].type == 'string' &&
              forms[0].config &&
              forms[0].config['ui-data'] &&
              forms[0].config['ui-data']['type'] == 'select'
            "
            :options="
              (forms[0].config['ui-data'] &&
                forms[0].config['ui-data']['options']) ||
              {}
            "
            :id="form.key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            :state="
              v$.form[forms[0].key] && v$.form[forms[0].key].$error
                ? false
                : null
            "
          ></b-form-select>
          <b-form-radio-group
            v-else-if="
              forms[0].type == 'string' &&
              forms[0].config &&
              forms[0].config['ui-data'] &&
              forms[0].config['ui-data']['type'] == 'radio'
            "
            :options="
              (forms[0].config['ui-data'] &&
                forms[0].config['ui-data']['options']) ||
              {}
            "
            :id="form.key"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form[forms[0].key]"
            :state="
              v$.form[forms[0].key] && v$.form[forms[0].key].$error
                ? false
                : null
            "
            :disabled="!editable"
          ></b-form-radio-group>
          <b-form-input
            v-else
            :id="form.key"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="form[forms[0].key]"
            :state="
              v$.form[forms[0].key] && v$.form[forms[0].key].$error
                ? false
                : null
            "
            :disabled="!editable"
          ></b-form-input>

          <b-form-invalid-feedback
            :state="
              v$.form[forms[0].key] && v$.form[forms[0].key].$error
                ? false
                : null
            "
            v-for="(error, i) in v$.form[forms[0].key].$errors"
            :key="i"
          >
            {{ error.$message || "欄位為必填" }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-else
          label-cols="12"
          label-cols-lg="2"
          label-size="m"
          :label="forms[0] && forms[0].category"
          label-class="font-weight-bold"
          class="group pt-4"
          v-for="(forms, i) in formGroups"
          :key="i"
        >
          <section class="row mb-8">
            <div class="col-12 col-xl-8">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                :description="f.description || ''"
                :label="f.name || ''"
                :label-for="f.key || ''"
                v-for="f in forms"
                :key="f.id"
              >
                <b-form-checkbox
                  v-if="f.type == 'boolean'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :name="f.key"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!editable"
                ></b-form-checkbox>
                <color-picker
                  :id="f.key"
                  v-model="form[f.key]"
                  v-else-if="f.type == 'color'"
                ></color-picker>
                <b-form-checkbox-group
                  v-else-if="
                    f.type == 'array' &&
                    f.config &&
                    f.config['ui-data'] &&
                    f.config['ui-data']['type'] == 'array-checkbox'
                  "
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :options="f.config['ui-data']['options']"
                  :name="f.key"
                  :disabled="!editable"
                ></b-form-checkbox-group>
                <editor
                  v-else-if="f.type == 'json' || f.type == 'array'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  width="100%"
                  height="200px"
                  @init="editorInit"
                  lang="json"
                  theme="chrome"
                >
                </editor>
                <editor
                  v-else-if="f.type == 'html'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  width="100%"
                  height="200px"
                  @init="editorInit"
                  lang="html"
                  theme="chrome"
                  :disabled="!editable"
                >
                </editor>
                <b-form-input
                  v-else-if="f.type == 'number'"
                  :id="f.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  v-model="form[f.key]"
                ></b-form-input>
                <button
                  v-else-if="f.type == 'file'"
                  class="
                    btn btn-link
                    px-2
                    text-dark
                    align-self-start
                    upload-button
                  "
                >
                  <b-form-file @change="upload($event, f.key)" :disabled="!editable" />
                  <a
                    v-if="!!form[f.key]"
                    @click="form[f.key] = null"
                    class="btn-sm btn-outline-danger"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                  <a :href="form[f.key]" target="_blank"
                    ><small> 目前路徑：{{ form[f.key] || "未上傳" }} </small></a
                  >
                </button>
                <b-form-select
                  v-else-if="
                    f.type == 'string' &&
                    f.config &&
                    f.config['ui-data'] &&
                    f.config['ui-data']['type'] == 'select'
                  "
                  :options="
                    (f.config['ui-data'] && f.config['ui-data']['options']) ||
                    {}
                  "
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :state="
                    v$.form[f.key] && v$.form[f.key].$error ? false : null
                  "
                  :disabled="!editable"
                ></b-form-select>
                <b-form-radio-group
                  v-else-if="
                    f.type == 'string' &&
                    f.config &&
                    f.config['ui-data'] &&
                    f.config['ui-data']['type'] == 'radio'
                  "
                  :options="
                    (f.config['ui-data'] && f.config['ui-data']['options']) ||
                    {}
                  "
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form[f.key]"
                  :state="
                    v$.form[f.key] && v$.form[f.key].$error ? false : null
                  "
                  :disabled="!editable"
                ></b-form-radio-group>
                <b-form-input
                  v-else
                  :id="form.key"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="form[f.key]"
                  :state="
                    v$.form[f.key] && v$.form[f.key].$error ? false : null
                  "
                  :disabled="!editable"
                ></b-form-input>

                <b-form-invalid-feedback
                  :state="
                    v$.form[f.key] && v$.form[f.key].$error ? false : null
                  "
                  v-for="(error, i) in v$.form[f.key].$errors"
                  :key="i"
                >
                  {{ error.$message || "欄位為必填" }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </section>
        </b-form-group>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            class="mr-2"
            variant="success"
            @click="handleSubmit(false)"
            :disabled="isLoading"
            v-if="editable"
          >
            儲存
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
            :disabled="isLoading"
            v-if="!isShownByVariableCode"
          >
            儲存後關閉
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.group + .group {
  border-top: 1px solid #cdd6dc !important;
}
</style>
<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, numeric, helpers } from "@vuelidate/validators";
import moduleApi from "../../../apis/module";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import editor from "vue2-ace-editor";
import fileMixin from "@/mixins/uploadFile";
import _ from "lodash";
import ColorPicker from "@/components/ColorPicker";
import organizationVariableApi from "@/apis/organization-variable";

const mustBeJson = (value) => {
  try {
    if (typeof value === "string") JSON.parse(value);
    return { $valid: true };
  } catch (e) {
    return {
      $valid: false,
      message: "必須為 JSON 格式",
    };
  }
};

const mustBeArray = (value) => {
  try {
    if (
      (typeof value === "string" && Array.isArray(JSON.parse(value))) ||
      Array.isArray(value)
    ) {
      return { $valid: true };
    }
    return {
      $valid: false,
      message: "必須為 Array 格式",
    };
  } catch (e) {
    return {
      $valid: false,
      message: "必須為 Array 格式",
    };
  }
};

export default {
  mixins: [fileMixin],
  components: { editor, "color-picker": ColorPicker },
  validationConfig: {
    $lazy: true,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    /*
     *  由於欄位是個動態 array ，因此 vuelidate 幾乎觸發不了驗證
     * ，因此編輯時暫時將
     *  forms = [{key: 'k', value: 'v'}]
     *  轉為form = {k:v}
     *  最後 submit 再轉換回來
     */
    return {
      code: null,
      name: null,
      color: "",
      isLoading: false,
      isSubmmiting: false,
      forms: [],
      form: reactive({}),
      formGroups: {},
      module: {},
      fetchedVariableId: null,
      canEdit: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.organization.id;
    },
    isShownByVariableCode() {
      return this.$route.name == 'OrganizationVariableConfigByCode'
    },
    editable() {
      return !this.isShownByVariableCode || this.canEdit
    },
    codeName() {
      const defaultMap = {
        "2DFIRE-CONFIG": "TAKOPOS",
        "SHOPLINE-CONFIG": "SHOPLINE",
        "POSPAL-CONFIG": "瑞乘POS"
      }
      return defaultMap[this.$route.params.organization_variable_id] || this.$route.params.organization_variable_id
    },
    // 這裡路徑有可能會是變數的 key 不一定是 id
    moduleId() {
      return this.isShownByVariableCode ? this.fetchedVariableId: this.$route.params.organization_variable_id;
    },
    // 組織參數管理元件是由 OrganizationVariableList, OrganizationVariableListByMerchant 共用
    isByMerchant() {
      return !!this.$route.params.merchant_id;
    }
  },
  validations() {
    let rules = {
      form: {},
    };
    this.forms.forEach((form) => {
      rules.form[form.key] = {};
      if (form.config && !!form.config.required) {
        rules.form[form.key] = {
          required: helpers.withMessage("此欄位為必填", required),
        };
      }
      switch (form.type) {
        case "number":
          rules.form[form.key]["numeric"] = helpers.withMessage(
            "此欄位必須為數字",
            numeric
          );
          break;
        case "json":
          rules.form[form.key]["mustBeJson"] = helpers.withMessage(
            "此欄位必須為 JSON 格式",
            mustBeJson
          );
          break;
        case "array":
          rules.form[form.key]["mustBeArray"] = helpers.withMessage(
            "此欄位必須為 Array 格式",
            mustBeArray
          );
          break;
      }
    });
    return rules;
  },
  mounted() {
    if (!this.checkPermission([consts.ORGANIZATION_VARIABLE])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({
            name: this.isByMerchant
              ? "OrganizationVariableListByMerchant"
              : "OrganizationVariableList",
          });
        });
    } else if (this.moduleId || this.isShownByVariableCode) {
      this.fetchModule();
    }
  },
  methods: {
    editorInit() {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/html");
      require("brace/theme/chrome");
    },
    async fetchModule() {
      try {
        this.isLoading = true;
        console.log('haosdifjj', this.isShownByVariableCode)
        const {
          data: { data: module },
        } = this.isShownByVariableCode ?
          await organizationVariableApi.getByKey({
            org_code: this.orgId,
            key: this.$route.params.organization_variable_id,
          })
          : await organizationVariableApi.get({
          org_code: this.orgId,
          organization_variable_id: this.moduleId,
        });
        this.fetchedVariableId = module.id
        this.module = module;
        const { data } = await moduleApi.getModuleConfigMapByType(
          this.orgId,
          this.moduleId,
          "organization_variables",
          this.module.type
        );
        this.name = data.meta.name;
        this.code = data.meta.code;
        this.forms = data.data.map((form) => {
          switch (form.type) {
            case "json":
            case "array":
              if (
                form.type == "array" &&
                form.config &&
                form.config["ui-data"] &&
                form.config["ui-data"]["type"] == "array-checkbox"
              ) {
                form.config["ui-data"]["options"] = form.config["ui-data"][
                  "options"
                ].sort((a, b) => a.order - b.order);
                form.value = form.value || [];
                break;
              }
              form.value = JSON.stringify(form.value, null, 2) || "";
              break;
            case "string":
              form.value = form.value || "";
              break;
          }
          this.$set(this.form, form.key, form.value);
          return form;
        });
        this.formGroups = _(this.forms)
          .groupBy((form) => form.category)
          .map((group) => _(group).orderBy("order").value())
          .sortBy((g) => {
            return g && g[0].category;
          })
          .value();
      } catch (error) {
        console.error("error", error);
      } finally {
        this.isLoading = false;
      }
    },
    async upload(e, key) {
      this.isLoading = true;
      let url = await this.uploadFile(e.target.files[0]);
      this.form[key] = url;
      this.isLoading = false;
      return url;
    },
    async handleSubmit(redirect = true) {
      const result = await this.v$.$validate();
      if (!result) return;
      this.handleUpdate(redirect);
    },
    async handleUpdate(redirect = true) {
      try {
        this.isSubmmiting = true;
        let forms = this.forms.map((form) => {
          form.value = this.form[form.key];
          if (form.type == "json" || form.type == "array") {
            if (
              form.type == "array" &&
              form.config &&
              form.config["ui-data"] &&
              form.config["ui-data"]["type"] == "array-checkbox"
            ) {
              return form;
            }
            return { ...form, value: JSON.parse(form.value) };
          }
          return form;
        });
        let module = {
          data: forms,
        };
        await moduleApi.updateModuleConfigMapByType(
          this.orgId,
          this.moduleId,
          module,
          "organization_variables",
          this.module.type
        );
        this.showSuccessPopup(redirect);
      } catch (error) {
        console.log(error);
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({
        name: this.isByMerchant
          ? "OrganizationVariableListByMerchant"
          : "OrganizationVariableList",
      });
    },
    showSuccessPopup(redirect = true) {
      this.$swal
        .fire({
          type: "success",
          text: "修改成功",
        })
        .then(() => {
          if (redirect) {
            this.$router.push({
              name: this.isByMerchant
                ? "OrganizationVariableListByMerchant"
                : "OrganizationVariableList",
            });
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>
